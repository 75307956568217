import React from "react";
import { Heading, Subheading } from "../../components/heading";
import { Strong, Text, TextLink } from "../../components/text";
import ReactMarkdown from "react-markdown";
import { Divider } from "../../components/divider";
import { Button } from "../../components/button";
import { ArrowRightCircleIcon } from "@heroicons/react/16/solid";
import Container from "../../components/container";
import { Li, Ul } from "../../components/list";

interface BoxProps {
  heading: string | null | undefined;
  subheading: string | null | undefined;
  pricing: string | null | undefined;
  children?: React.ReactNode;
  contactSales?: boolean;
}

const Box: React.FC<BoxProps> = ({
  heading,
  subheading,
  pricing,
  children,
  contactSales = false,
}) => {
  return (
    <div className="h-full bg-white rounded-xl flex flex-col justify-start items-start shadow-md border border-zinc-300">
      <Heading className="mx-8 mt-8">{heading}</Heading>
      <Subheading className="mx-8 mb-6 text-zinc-500/75">
        {subheading}
      </Subheading>
      <Divider />
      <div className="px-8 mt-6 mb-4">{children}</div>
      <div className="flex flex-row justify-between items-center mt-auto mb-8 h-8 w-full px-8">
        <Text
          className="font-bold"
          style={{
            fontSize: "1.5rem",
          }}
        >
          {pricing}
        </Text>
        {contactSales && (
          <Button href="/contact" className="hidden lg:block">
            Contact Sales
          </Button>
        )}
      </div>
    </div>
  );
};

interface PricingSectionProps {}

const PrcingSection: React.FC<PricingSectionProps> = ({}) => {
  return (
    <>
      <Heading>Flexible Pricing Models</Heading>
      <Text className="mb-8">
        Our pricing model is tailored to your needs. Only pay what you use.
      </Text>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <Box
          heading={"App"}
          subheading={"Showcase your products and services"}
          pricing={"Free"}
        >
          <Ul>
            <Li>
              <ArrowRightCircleIcon />
              Custom interface for your device
            </Li>
            <Li>
              <ArrowRightCircleIcon />
              Tap into existing user base
            </Li>
            <Li>
              <ArrowRightCircleIcon />
              In-App branding and custom colors
            </Li>
            <Li>
              <ArrowRightCircleIcon />
              Benefit from continous updates
            </Li>
          </Ul>
        </Box>
        <Box
          heading={"Device"}
          subheading={"Every integrated digital device"}
          pricing={"3€ per month"}
        >
          <Ul>
            <Li>
              <ArrowRightCircleIcon className="h-6 w-6" />
              Internet access
            </Li>
            <Li>
              <ArrowRightCircleIcon className="h-6 w-6" />
              Digital Twin
            </Li>
          </Ul>
        </Box>

        <Box
          heading={"API Access"}
          subheading={"Use our public API free of charge"}
          pricing={"Free"}
        >
          <Ul>
            <Li>
              <ArrowRightCircleIcon className="h-6 w-6" />
              Typescript SDK
            </Li>
            <Li>
              <ArrowRightCircleIcon className="h-6 w-6" />
              Postman collection
            </Li>
            <Li>
              <ArrowRightCircleIcon className="h-6 w-6" />
              API Documentation
            </Li>
          </Ul>
        </Box>
        <Box
          heading={"Digitalize Kit"}
          subheading={"Digitalize and integrate any product"}
          pricing={"On Request"}
          contactSales={true}
        >
          <Text>
            Plug-and-Play electronic kit to digitzie and integrate any mobility
            or infrastructure product into the JUHUU platform.
          </Text>
        </Box>

        <Box
          heading={"Payment Service"}
          subheading={"Commission on every transaction"}
          pricing={"10% on every charge"}
        >
          <Ul>
            <Li>
              <ArrowRightCircleIcon className="h-6 w-6" />
              Automated invoicing and billing
            </Li>
            <Li>
              <ArrowRightCircleIcon className="h-6 w-6" />
              Automated payouts to any bank account
            </Li>
            <Li>
              <ArrowRightCircleIcon className="h-6 w-6" />
              Transaction fees included
            </Li>
          </Ul>
        </Box>
        <Box
          heading={"Custom Pricing"}
          subheading={"Get custom pricing package"}
          pricing={"On Request"}
          contactSales={true}
        >
          <Text>
            Get a custom pricing for your specific needs. Contact us for more
            information. If you use more services.
          </Text>
        </Box>
      </div>
    </>
  );
};

export default PrcingSection;
