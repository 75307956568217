import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Heading } from "../../components/heading";
import { Text } from "../../components/text";
import HeroSection from "../../sections/hero/hero-section";
import { JUHUU } from "@juhuu/sdk-ts";
import { juhuu } from "../../juhuuClass";
import Container from "../../components/container";
import { Button } from "../../components/button";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from "../../components/dialog";

interface ProductProps {
  product: JUHUU.Product.Object;
  onClick: (product: JUHUU.Product.Object) => void;
}

const Product: React.FC<ProductProps> = ({ product, onClick }) => {
  const truncatedText = useMemo(() => {
    if (
      product.previewText.en === undefined ||
      product.previewText.en === null
    ) {
      return "";
    }

    return product.previewText.en.substring(0, 100) + " ...";
  }, [product.previewText.en]);

  return (
    <div
      className="width-full rounded-xl bg-white p-8 flex flex-col justify-start items-start h-full cursor-pointer shadow-md border border-zinc-300"
      onClick={() => onClick(product)}
    >
      <div>
        <img className="w-full" src={product.bannerImageLight[0]} />
      </div>
      <Heading className="">{product.name}</Heading>
      <Text className="mb-4">{truncatedText}</Text>
    </div>
  );
};

interface MarketplaceRouteProps {}

const MarketplaceRoute: React.FC<MarketplaceRouteProps> = () => {
  const [productArray, setProductArray] = useState<JUHUU.Product.Object[]>([]);
  const [selectedProduct, setSelectedProduct] =
    useState<JUHUU.Product.Object | null>(null);

  const handleRefresh = useCallback(async () => {
    const query = await juhuu.products.list({});

    if (query.ok === false) {
      return;
    }

    setProductArray(query.data);
  }, []);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  const handleOnClick = useCallback((product: JUHUU.Product.Object) => {
    console.log(product);
  }, []);

  return (
    <>
      <HeroSection
        heading={"Marketplace"}
        primaryAction={"Contact Sales"}
        primaryActionHref="/contact"
        subtitle="Discover the JUHUU Marketplace, where cities, municipalities, and other customers can browse fully integrated micro-mobility solutions from various manufacturers. Compare prices, specs, and connect directly with suppliers."
      />
      <Container>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {productArray.map((product) => {
            return <Product product={product} onClick={setSelectedProduct} />;
          })}
        </div>
      </Container>
      <Dialog
        open={selectedProduct !== null}
        onClose={() => setSelectedProduct(null)}
        style={{
          zIndex: 100,
          position: "absolute",
        }}
        size="3xl"
      >
        <DialogTitle>{selectedProduct?.name}</DialogTitle>
        <DialogDescription>{selectedProduct?.previewText.en}</DialogDescription>
        <DialogBody>
          <img className="w-full" src={selectedProduct?.bannerImageLight[0]} />
        </DialogBody>
        <DialogActions>
          <Button plain onClick={() => setSelectedProduct(null)}>
            Close
          </Button>
          {selectedProduct?.articleId !== null && (
            <Button
              onClick={() =>
                window.open(
                  "https://docs.juhuu.app/articles/" +
                    selectedProduct?.articleId,
                  "_blank"
                )
              }
            >
              Read more
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MarketplaceRoute;
