import React from "react";
import { Heading, Subheading } from "./heading";
import { Strong, Text, TextLink } from "./text";
import ReactMarkdown from "react-markdown";
import { Divider } from "./divider";

interface LiProps {
  children?: React.ReactNode;
}

const Li: React.FC<LiProps> = ({ children }) => {
  return (
    <div className="flex flex-row gap-2 my-2 data-[slot=icon]:*:size-5 items-center justify-start">
      {children}
    </div>
  );
};

export { Li };

interface UlProps {
  children?: React.ReactNode;
}

const Ul: React.FC<UlProps> = ({ children }) => {
  return <div>{children}</div>;
};

export { Ul };

interface OlProps {
  children?: React.ReactNode;
}

const Ol: React.FC<OlProps> = ({ children }) => {
  return <div>{children}</div>;
};

export { Ol };
