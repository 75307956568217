import React from "react";
import { Heading } from "../../components/heading";
import pay from "./pay.png";
import select from "./select.png";
import scan from "./scan.png";
import use from "./use.png";
import { TextLink } from "../../components/text";

interface BoxProps {
  heading: string;
  step: number;
  text: React.ReactNode;
  imgSrc: string;
}

const Box: React.FC<BoxProps> = ({
  heading,
  text: subheading,
  imgSrc,
  step,
}) => {
  return (
    <div
      className="flex flex-col justify-start items-start"
      style={{
        overflow: "hidden",
      }}
    >
      <img
        src={imgSrc}
        style={{
          objectFit: "cover",
          objectPosition: "top",
          aspectRatio: "1339 / 2716",
          width: "100%",
        }}
      />
      <div className="mx-4 mt-4">
        <p className="text-sm text-zinc-500">STEP {step}</p>
        <Heading className="">{heading}</Heading>
        <p className="text-zinc-500/75 text-xl mt-4">{subheading}</p>
      </div>
    </div>
  );
};

interface UserStorySectionProps {}

const UserStorySection: React.FC<UserStorySectionProps> = ({}) => {
  return (
    <>
      <div className="hidden lg:block">
        <div className="grid grid-cols-1 sm:grid-cols-4 gap-12">
          <Box
            heading={"Scan QR-Code"}
            text={
              "Users use the JUHUU app to scan the QR-codes on your products."
            }
            imgSrc={scan}
            step={1}
          />
          <Box
            heading={"Select Tariff"}
            text={
              "Your logo, your colors, your custom reservations & subscriptions."
            }
            imgSrc={select}
            step={2}
          />
          <Box
            heading={"Pay"}
            text={
              "We do the billing. Revenue is transfered to your bank account every month."
            }
            imgSrc={pay}
            step={3}
          />
          <Box
            heading={"Use Product"}
            text={
              <>
                Design a unique and custom interface for your product.
                <br />
                <TextLink href="https://www.youtube.com/watch?v=GDdELAYoAtw">
                  Watch video
                </TextLink>
              </>
            }
            imgSrc={use}
            step={4}
          />
        </div>
      </div>
    </>
  );
};

export default UserStorySection;
