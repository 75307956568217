import React from "react";
import HeroSection from "../../sections/hero/hero-section";
import PartnerSection from "../../sections/partner/partner-section";
import CallToActionSection from "../../sections/callToAction/call-to-action-section";
import PrcingSection from "../../sections/pricing/pricing-section";
import heroDashboard from "./hero-dashboard.png";
import heroPhone from "./hero-phone.png";
import Container from "../../components/container";
import {
  ProductBody,
  ProductCallToAction,
  ProductDescription,
  ProductDisplay,
  ProductFeature,
  ProductFeatureItem,
  ProductHeader,
  ProductHeading,
  ProductSection,
  ProductSlogan,
} from "../../sections/product/product-section";
import {
  BuildingStorefrontIcon,
  ComputerDesktopIcon,
  CpuChipIcon,
  DevicePhoneMobileIcon,
  PaintBrushIcon,
} from "@heroicons/react/16/solid";
import { Button } from "../../components/button";
import { TextLink } from "../../components/text";
import UserStorySection from "../../sections/userStory/user-story-section";
import app from "./app.gif";
import dashboard from "./dashboard.png";
import YouTubeVideo from "../../components/youtube-video";
import overview_small from "./overview_small.png";

interface HomeRouteProps {}

const HomeRoute: React.FC<HomeRouteProps> = () => {
  return (
    <>
      <HeroSection
        heading={"Mobility Platform to grow your Business"}
        subtitle={
          "JUHUU is a flexible platform to manage mobility service offerings, handle payments, and let users find, book, and access products with ease. Operators can also integrate with partner apps, gaining increased visibility and simplifying management."
        }
        showEmailInput={true}
      >
        <div
          style={{
            width: "170%",
            // border: "1px solid red",
            position: "relative",
            top: "0px",
            left: "0px",
          }}
        >
          <img
            src={heroDashboard}
            alt=""
            className="shadow-2xl"
            style={{
              position: "absolute",
              width: "1000px",
              left: "350px",
              top: "-70px",
            }}
          />
          <img
            src={heroPhone}
            alt=""
            className="shadow-2xl"
            style={{
              position: "absolute",
              width: "300px",
              left: "200px",
              borderRadius: "20px",
            }}
          />
        </div>
      </HeroSection>
      <Container className="mb-24">
        <PartnerSection />
      </Container>
      {/* bg-violet-100 */}
      <Container className="py-24">
        <UserStorySection />
      </Container>

      <Container className="py-24 bg-white">
        <ProductSection>
          <ProductBody>
            <ProductHeader>
              <DevicePhoneMobileIcon />
              <ProductHeading>Smartphone App</ProductHeading>
            </ProductHeader>
            <ProductSlogan>
              All in one access to Mobility and Infrastructure services
            </ProductSlogan>
            <ProductDescription>
              JUHUU App is the link between your customers and your service
              offerings. The moblity and infrastructure services you integrate
              into JUHUU can be rented by users through our centralized app.
            </ProductDescription>
            <ProductCallToAction>
              <Button href={"https://dashboard.juhuu.app/"}>Start</Button>
              <Button plain href="/contact">
                Contact Sales
              </Button>
            </ProductCallToAction>

            <ProductFeature>
              <ProductFeatureItem>
                <TextLink
                  target="_blank"
                  href="https://docs.juhuu.app/articles/67011e6e35ed1ec9444a3c1d"
                >
                  JUHUU Buddy
                </TextLink>{" "}
                supports your customers with AI while you are not available.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink
                  target="_blank"
                  href="https://docs.juhuu.app/articles/6701162935ed1ec9444a3945"
                >
                  Markers
                </TextLink>{" "}
                to display on the map in the JUHUU App
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink
                  target="_blank"
                  href="https://docs.juhuu.app/articles/67011c5435ed1ec9444a3b37"
                >
                  Invoicing Service
                </TextLink>{" "}
                to download and manage all customer invoices.
              </ProductFeatureItem>
            </ProductFeature>
          </ProductBody>
          <ProductDisplay>
            <img
              src={app}
              alt=""
              style={{ height: "600px", position: "relative", right: "25%" }}
            />
          </ProductDisplay>
        </ProductSection>
      </Container>
      <Container className="py-24">
        <ProductSection>
          <ProductBody>
            <ProductHeader>
              <CpuChipIcon />
              <ProductHeading>Digitalize Kit</ProductHeading>
            </ProductHeader>
            <ProductSlogan>
              Digitalize your Infrastructure with ease
            </ProductSlogan>
            <ProductDescription>
              Transform your infrastructure with JUHUU. Effortlessly connect
              your products to the cloud platform, enabling real-time control
              and integration without any complexity.
            </ProductDescription>
            <ProductCallToAction>
              <Button href={"https://dashboard.juhuu.app/"}>Start</Button>
              <Button plain href="/contact">
                Contact Sales
              </Button>
            </ProductCallToAction>
            <ProductFeature>
              <ProductFeatureItem>
                <ProductFeatureItem>
                  <TextLink
                    target="_blank"
                    href="https://docs.juhuu.app/articles/670501d61113f7de8a869164"
                  >
                    Solar & battery
                  </TextLink>{" "}
                  for fully autonomous, off-grid operation of your
                  infrastructure.
                </ProductFeatureItem>
                <TextLink
                  target="_blank"
                  href="https://docs.juhuu.app/articles/67039ff635ed1ec9444a7240"
                >
                  Capabilities of the IoT Module
                </TextLink>{" "}
                for flexible device control, connectivity, and power options
                tailored to your infrastructure needs
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink
                  target="_blank"
                  href="https://docs.juhuu.app/articles/670502291113f7de8a869187"
                >
                  Free Test Kit
                </TextLink>{" "}
                for the fastest way to test our system free of charge.
              </ProductFeatureItem>
            </ProductFeature>
          </ProductBody>
          <ProductDisplay>
            <img src={overview_small} className="rounded-xl w-full" />
          </ProductDisplay>
        </ProductSection>
      </Container>
      <Container className="py-24 bg-white">
        <ProductSection>
          <ProductBody>
            <ProductHeader>
              <ComputerDesktopIcon />
              <ProductHeading>Dashboard</ProductHeading>
            </ProductHeader>
            <ProductSlogan>
              Take full control of your operations with real-time insights.
            </ProductSlogan>
            <ProductDescription>
              Our all-in-one dashboard enables operators to manage tariffs,
              track rentals, monitor customer reservations, and configure
              devices effortlessly. Control every aspect of your business
              operations from a single, intuitive interface.
            </ProductDescription>
            <ProductCallToAction>
              <Button href={"https://dashboard.juhuu.app/"}>Start</Button>
              <Button plain href="/contact">
                Contact Sales
              </Button>
            </ProductCallToAction>
            <ProductFeature>
              <ProductFeatureItem>
                <TextLink
                  target="_blank"
                  href="https://docs.juhuu.app/articles/670394b835ed1ec9444a6e8c"
                >
                  Digital Twins
                </TextLink>{" "}
                for a realtime overview of your devices
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink
                  target="_blank"
                  href="https://docs.juhuu.app/articles/66e9fd559f98e98c8be2354e"
                >
                  Customer overview
                </TextLink>{" "}
                to track who is using your products.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink
                  target="_blank"
                  href="https://docs.juhuu.app/articles/6701183135ed1ec9444a3a51"
                >
                  QR-Codes
                </TextLink>{" "}
                to link users to your locations
              </ProductFeatureItem>
            </ProductFeature>
          </ProductBody>
          <ProductDisplay>
            <YouTubeVideo
              url="https://youtu.be/-Ctv3LqCqyA"
              thumbnail={dashboard}
            />
          </ProductDisplay>
        </ProductSection>
      </Container>
      <Container className="py-24">
        <ProductSection>
          <ProductBody>
            <ProductHeader>
              <BuildingStorefrontIcon />
              <ProductHeading>Marketplace</ProductHeading>
            </ProductHeader>
            <ProductSlogan>
              Discover the best mircomobility solutions in one place
            </ProductSlogan>
            <ProductDescription>
              Discover the JUHUU Marketplace, where cities, municipalities, and
              other customers can browse fully integrated micro-mobility
              solutions from various manufacturers. Compare prices, specs, and
              connect directly with suppliers.
            </ProductDescription>
            <ProductCallToAction>
              <Button href={"https://dashboard.juhuu.app/"}>Start</Button>
              <Button plain href="/contact">
                Contact Sales
              </Button>
            </ProductCallToAction>
            {/* <ProductFeature>
              <ProductFeatureItem>
                <TextLink target="_blank" href="#">Locations</TextLink> to group products
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="#">Markers</TextLink> to display on the map
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="#">QR-Codes</TextLink> to link users to your
                locations
              </ProductFeatureItem>
            </ProductFeature> */}
          </ProductBody>
          <ProductDisplay></ProductDisplay>
        </ProductSection>
      </Container>
      <Container className="bg-white">
        <CallToActionSection className="py-24" />
      </Container>
      <Container className="pt-16">
        <PrcingSection />
      </Container>
    </>
  );
};

export default HomeRoute;
