import React, { FC, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useCookie } from "../context/CookieContext";
import { Button } from "./button";

interface VideoPlayerProps {
  url: string;
  thumbnail: string;
}

const VideoPlayer: FC<VideoPlayerProps> = ({ url, thumbnail }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const playerRef = useRef<HTMLDivElement>(null);
  const { allowCookies, handleDecision } = useCookie();

  // Function to add "vq=hd1080" query parameter to YouTube URLs
  const getHighQualityUrl = (url: string): string => {
    try {
      const videoUrl = new URL(url);
      // Check if the URL is a YouTube video
      if (
        videoUrl.hostname.includes("youtube.com") ||
        videoUrl.hostname.includes("youtu.be")
      ) {
        // Add the vq=hd1080 query parameter if it doesn’t already exist
        if (!videoUrl.searchParams.has("vq")) {
          videoUrl.searchParams.append("vq", "hd1080");
        }
      }
      return videoUrl.toString();
    } catch (error) {
      console.error("Invalid URL:", error);
      return url; // Return the original URL if parsing fails
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsPlaying(true);
          } else {
            setIsPlaying(false);
          }
        });
      },
      { threshold: 0.5 } // Trigger when 50% of the video is in view
    );

    if (playerRef.current) {
      observer.observe(playerRef.current);
    }

    return () => {
      if (playerRef.current) {
        observer.unobserve(playerRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={playerRef}
      className="video-player w-full aspect-video rounded-2xl overflow-hidden"
    >
      {allowCookies === true ? (
        <ReactPlayer
          url={getHighQualityUrl(url)} // Use modified URL if YouTube video
          width="100%"
          height="100%"
          muted={true}
          playing={isPlaying}
          loop={true}
          playsinline // Enable inline playback for mobile
          controls // Optional: display video controls
        />
      ) : (
        <div className="w-full h-full flex items-start justify-center flex-col">
          <img
            src={thumbnail}
            alt="Video thumbnail"
            style={{
              width: "100%",
              height: "88%",
              objectFit: "cover",
            }}
            className="rounded-2xl overflow-hidden"
          />
          <div
            style={{
              height: "12%",
            }}
            className="flex items-center justify-center w-full"
          >
            <Button onClick={() => handleDecision("accepted")}>
              Allow cookies to play video
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
