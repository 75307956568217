import React from "react";
import { Heading, Subheading } from "../../components/heading";
import { Strong, Text, TextLink } from "../../components/text";

import Card from "../../components/card";
import Container from "../../components/container";

interface CallToActionSectionProps {
  className?: string | undefined;
}

const CallToActionSection: React.FC<CallToActionSectionProps> = ({
  className,
}) => {
  return (
    <div className={className}>
      <Heading>Help us understand your needs</Heading>
      <Text className="mb-8">Choose your use case scenario.</Text>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <Card
          title="Municipality or Company"
          description="I want to offer my citizens or employees digital mobility and infrastructure products such as bike storage, car parking, ..."
          href="https://docs.juhuu.app/articles/67039cd235ed1ec9444a70f5"
        />
        <Card
          title="Manufacturer"
          description="I am a manufacturer of mobility or infrastructure products and want to integrate my products into the JUHUU platform."
          href="https://docs.juhuu.app/articles/67039d7235ed1ec9444a7113"
        />
        <Card
          title="Mobility Service Provider"
          description="I already have digital products and want to offer my services and products on the JUHUU platform."
          href="https://docs.juhuu.app/articles/67039d9e35ed1ec9444a7136"
        />
        <Card
          title="Retailer"
          description="I am a retailer or want to become a retailer and sell digital mobility or infrastructure products."
          href="https://docs.juhuu.app/articles/67039dcc35ed1ec9444a7159"
        />
        <Card
          title="Consumer Platform"
          description="I am running a consumer platform such as DB-Navigator in- or outside the mobilty sector and want to offer addtional services to my customers."
          href="https://docs.juhuu.app/articles/67039df735ed1ec9444a717c"
        />
        <Card
          title="User of the JUHUU app"
          description="I am interested in using the JUHUU app."
          href="https://docs.juhuu.app/articles/67039e3735ed1ec9444a719f"
        />
      </div>
    </div>
  );
};

export default CallToActionSection;
