import React from "react";
import { Button } from "../../components/button";
import Container from "../../components/container";
import { Input } from "../../components/input";
import SendInquiry from "../../components/send-inquiry";
import AppStoreImage from "../../components/apple/app-store-image";
import PlayStoreImage from "../../components/google/play-store-image";

interface HeroSectionProps {
  heading: string;
  subtitle?: string;
  primaryAction?: string;
  primaryActionHref?: string;
  secondaryAction?: string;
  secondaryActionHref?: string;
  children?: React.ReactNode;
  showEmailInput?: boolean;
  showAppDownload?: boolean;
}

const HeroSection: React.FC<HeroSectionProps> = ({
  heading,
  subtitle,
  primaryAction,
  secondaryAction,
  children,
  primaryActionHref = "#",
  secondaryActionHref,
  showEmailInput = false,
  showAppDownload = false,
}) => {
  return (
    <Container>
      <div
        className="flex flex-col lg:flex-row mt-32 mb-32"
        style={{
          position: "relative",
        }}
      >
        {/* Left section: Full width on smaller devices */}
        <div className="w-full lg:w-2/5">
          <p className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold leading-10 sm:leading-snug md:leading-tight lg:leading-tight">
            {heading}
          </p>
          <p
            style={{
              fontSize: "1.3rem",
              marginTop: "1rem",
            }}
          >
            {subtitle}
          </p>
          <div className="mt-8 flex gap-4 flex-row">
            {primaryAction !== undefined && (
              <Button href={primaryActionHref}>{primaryAction}</Button>
            )}

            {secondaryAction !== undefined && (
              <Button plain href={secondaryActionHref}>
                {secondaryAction}
              </Button>
            )}
            {showEmailInput && <SendInquiry message="homescreen" />}
            {showAppDownload && (
              <div className="flex flex-row justify-center items-center gap-4">
                <AppStoreImage className="w-44" />
                <PlayStoreImage className="w-44" />
              </div>
            )}
          </div>
        </div>

        {/* Right section: Hidden on smaller devices */}
        <div className="hidden lg:block lg:w-1/2">{children}</div>
      </div>
    </Container>
  );
};

export default HeroSection;
