import React, { useState } from "react";
import { juhuu } from "../juhuuClass";

interface SendInquiryProps {
  message: string;
}

const SendInquiry: React.FC<SendInquiryProps> = ({ message }) => {
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState<
    "not_sent" | "error" | "send" | "loading"
  >("not_sent");

  const handleSendInquiry = async () => {
    setStatus("loading");
    const query = await juhuu.settings.sendSupportRequest({
      message:
        "A user with the email address " +
        email +
        " sent the following message: " +
        message,
    });

    if (query.ok === false) {
      setStatus("error");
    } else {
      setStatus("send");
      setEmail("");
    }
  };

  return (
    <div>
      <div className="inline-flex flex-row justify-start items-center gap-4 border-2 border-gray-300 rounded-full overflow-hidden bg-white">
        <input
          type="email"
          placeholder="Email address"
          className="py-2 px-4 text-black w-60 border-transparent focus:border-transparent focus:ring-0 outline-none"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <div
          className="rounded-full py-2 px-4 bg-violet-700 font-bold text-white cursor-pointer whitespace-nowrap"
          onClick={() => handleSendInquiry()}
        >
          {status === "loading"
            ? "Please wait..."
            : status === "send"
            ? "Sent successfully!"
            : status === "error"
            ? "Error, click to try again."
            : "Send inquiry"}
        </div>
      </div>
    </div>
  );
};

export default SendInquiry;
