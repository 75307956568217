import { Juhuu } from "@juhuu/sdk-ts";

export const juhuu = new Juhuu({
  clientVersion: "1.0.300",
  environment: "production",
  getAccessToken: async () => {
    return localStorage.getItem("accessToken");
  },
  getRefreshToken: async () => {
    return localStorage.getItem("refreshToken");
  },
  onException: async (error) => {
    console.error(error);
    return "abort";
  },
  setAccessToken: async (accessToken) => {
    localStorage.setItem("accessToken", accessToken);
  },
  setRefreshToken: async (refreshToken) => {
    localStorage.setItem("refreshToken", refreshToken);
  },
});
