import React, { FC } from "react";
import apple from "./apple.webp";

interface AppStoreImageProps {
  className?: string;
}

const AppStoreImage: FC<AppStoreImageProps> = ({ className }) => {
  return (
    <img
      src={apple}
      className={"cursor-pointer " + className}
      onClick={() =>
        window.open(
          "https://apps.apple.com/at/app/juhuu/id1603108125",
          "_blank"
        )
      }
    />
  );
};

export default AppStoreImage;
