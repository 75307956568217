import React, { useState } from "react";
import { Text, TextLink } from "./text";
import { ArrowRightCircleIcon } from "@heroicons/react/16/solid";

interface CardProps {
  title: string;
  description: string;
  href?: string;
  className?: string;
  onClick?: () => void;
}

const Card: React.FC<CardProps> = ({
  title,
  description,
  href,
  className,
  onClick,
}) => {
  return (
    <>
      <div
        className={
          "bg-white border border-zinc-300 p-6 rounded-xl flex flex-row w-full justify-between items-start cursor-pointer shadow-md " +
          className
        }
        onClick={() => {
          if (href !== undefined) {
            window.open(href, "_blank");
          }

          if (onClick !== undefined) {
            onClick();
          }
        }}
      >
        <div className="w-5/6">
          <p
            style={{
              fontSize: "1.1rem",
              fontWeight: 600,
            }}
          >
            {title}
          </p>
          <Text>{description}</Text>
        </div>

        <div className="w-1/6 flex flex-row justify-end items-center mr-2 h-full">
          <ArrowRightCircleIcon className="w-8 h-8" />
        </div>
      </div>
    </>
  );
};

export default Card;

interface EmptyCardProps {
  children?: React.ReactNode;
  className?: string;
}

const EmptyCard: React.FC<EmptyCardProps> = ({ className, children }) => {
  return (
    <>
      <div
        className={
          "bg-white border border-zinc-300 p-6 rounded-xl cursor-pointer shadow-md " +
          className
        }
      >
        {children}
      </div>
    </>
  );
};

export { EmptyCard };
