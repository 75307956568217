import React from "react";
import { Heading, Subheading } from "../../components/heading";
import { Divider } from "../../components/divider";

import { Text, TextLink } from "../../components/text";
import HeroSection from "../../sections/hero/hero-section";
import PricingSection from "../../sections/pricing/pricing-section";
import Container from "../../components/container";

interface PricingRouteProps {}

const PricingRoute: React.FC<PricingRouteProps> = () => {
  return (
    <>
      <HeroSection
        heading={"Pricing"}
        primaryAction="Contact Sales"
        primaryActionHref="/contact"
        secondaryAction="Sign up"
        secondaryActionHref="https://identity.juhuu.app"
        subtitle="Flexible and fair pricing tailored to your needs, ensuring you only pay for what you use."
      />
      <Container>
        <PricingSection />
      </Container>
    </>
  );
};

export default PricingRoute;
