import React from "react";
import { TextLink } from "../../components/text";
import HeroSection from "../../sections/hero/hero-section";
import Container from "../../components/container";
import {
  ProductBody,
  ProductCallToAction,
  ProductDescription,
  ProductDisplay,
  ProductFeature,
  ProductFeatureItem,
  ProductHeader,
  ProductHeading,
  ProductSection,
  ProductSlogan,
} from "../../sections/product/product-section";
import {
  FireIcon,
  GlobeEuropeAfricaIcon,
  HeartIcon,
  UserGroupIcon,
} from "@heroicons/react/16/solid";
import { Button } from "../../components/button";
import bike from "./bike.png";
import car from "./car.png";
import infrastructure from "./infrastructure.png";
import scooter from "./scooter.png";
import tourism from "./tourism.png";

interface UseCaseRouteProps {}

const UseCaseRoute: React.FC<UseCaseRouteProps> = () => {
  return (
    <>
      <HeroSection
        heading={"Use Cases"}
        primaryAction={"Contact Sales"}
        subtitle="Transform your infrastructure with JUHUU! From bike parking to shared mobility, tourism, and car parking — our smart, digital solutions connect and manage your services effortlessly for enhanced efficiency and growth!"
        primaryActionHref="/contact"
      />
      <Container className="py-24">
        <ProductSection>
          <ProductBody>
            <ProductHeader>
              <HeartIcon />
              <ProductHeading>Bike Parking</ProductHeading>
            </ProductHeader>
            <ProductSlogan>
              Revolutionize bike parking with our cutting-edge platform.
            </ProductSlogan>
            <ProductDescription>
              Digitize your bike parking solutions with JUHUU and offer your
              clients, the operators, an edge. With our Digitalize Kit,
              manufacturers can quickly turn analog systems into smart,
              connected infrastructure. Operators, such as cities or private
              companies, can manage parking operations via the JUHUU Dashboard,
              while users enjoy seamless access through the JUHUU App, boosting
              both convenience and operational efficiency.
            </ProductDescription>
            <ProductCallToAction>
              <Button href={"https://dashboard.juhuu.app/"}>Start</Button>
              <Button plain href="/contact">
                Contact Sales
              </Button>
            </ProductCallToAction>
            <ProductFeature>
              <ProductFeatureItem>
                <TextLink target="_blank" href="/kit">
                  Digitalize Kit
                </TextLink>{" "}
                For digitizing analog parking systems quickly and easily.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="/app">
                  JUHUU App
                </TextLink>{" "}
                to let users use your bike parking effortlessly.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="/dashboard">
                  JUHUU Dashboard
                </TextLink>{" "}
                for managing all of bike parking in real-time.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink
                  target="_blank"
                  href="https://docs.juhuu.app/articles/67039d7235ed1ec9444a7113"
                >
                  Manufacturer overview
                </TextLink>{" "}
                for integrating into JUHUU's platform.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink
                  target="_blank"
                  href="https://docs.juhuu.app/articles/670e890d2d5ff6f4f26dee60"
                >
                  Advantages for operators
                </TextLink>{" "}
                to discover the benefits of using JUHUU.
              </ProductFeatureItem>
            </ProductFeature>
          </ProductBody>
          <ProductDisplay>
            <img src={bike} className="w-full rounded-xl" />
          </ProductDisplay>
        </ProductSection>
      </Container>
      <Container className="py-24 bg-white">
        <ProductSection>
          <ProductBody>
            <ProductHeader>
              <UserGroupIcon />
              <ProductHeading>Shared infrastructure</ProductHeading>
            </ProductHeader>
            <ProductSlogan>
              Connect, Manage, and Grow Your City's digital Infrastructure
            </ProductSlogan>
            <ProductDescription>
              Take your city's infrastructure to the next level by digitizing
              existing facilities or integrating new systems through JUHUU's
              advanced platform. By connecting your city's shared services with
              our robust software, residents can access and book infrastructure
              directly via the JUHUU App or your city's own front-end platforms.
              From real-time management to efficient resource utilization, JUHUU
              helps your city run smarter and greener.
            </ProductDescription>
            <ProductCallToAction>
              <Button href={"https://dashboard.juhuu.app/"}>Start</Button>
              <Button plain href="/contact">
                Contact Sales
              </Button>
            </ProductCallToAction>
            <ProductFeature>
              <ProductFeatureItem>
                <TextLink target="_blank" href="/kit">
                  Digitalize Kit
                </TextLink>{" "}
                For digitizing analog parking systems quickly and easily.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="/dashboard">
                  JUHUU Dashboard
                </TextLink>{" "}
                for managing all of bike parking in real-time.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink
                  target="_blank"
                  href="https://docs.juhuu.app/articles/67039df735ed1ec9444a717c"
                >
                  Partner Front Ends
                </TextLink>{" "}
                to integrate your services into other apps, maximizing
                visibility.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="/marketplace">
                  Marketplace
                </TextLink>{" "}
                for sourcing new products compatible with the JUHUU ecosystem.
              </ProductFeatureItem>
            </ProductFeature>
          </ProductBody>
          <ProductDisplay>
            <img src={infrastructure} className="w-full rounded-xl" />
          </ProductDisplay>
        </ProductSection>
      </Container>
      <Container className="py-24">
        <ProductSection>
          <ProductBody>
            <ProductHeader>
              <FireIcon />
              <ProductHeading>Car Parking</ProductHeading>
            </ProductHeader>
            <ProductSlogan>
              Turn Your Free Spaces into Profit with Smart Parking Solutions
            </ProductSlogan>
            <ProductDescription>
              Offer your parking spaces to a growing network of drivers through
              JUHUU front ends. Whether you have an outdoor spot , available
              spaces in garages or a multi-story garage, our platform connects
              you with users looking for parking solutions. Manage your
              bookings, set pricing, and track usage all from the JUHUU
              Dashboard.
            </ProductDescription>
            <ProductCallToAction>
              <Button href={"https://dashboard.juhuu.app/"}>Start</Button>
              <Button plain href="/contact">
                Contact Sales
              </Button>
            </ProductCallToAction>
            <ProductFeature>
              <ProductFeatureItem>
                <TextLink
                  target="_blank"
                  href="https://docs.juhuu.app/articles/67039d9e35ed1ec9444a7136"
                >
                  Car Parking Setup
                </TextLink>{" "}
                to learn how to set up your parking spaces on JUHUU.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink
                  target="_blank"
                  href="https://docs.juhuu.app/articles/67039d9e35ed1ec9444a7136"
                >
                  Advanced Features
                </TextLink>{" "}
                to discover future enhancements like video surveillance and
                automated license plate detection.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="/kit">
                  Digitize Kit
                </TextLink>{" "}
                to transform your infrastructure with JUHUU's plug-and-play
                solutions.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="/app">
                  JUHUU App
                </TextLink>{" "}
                to find out how users can easily book your parking spots via our
                app.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="/dashboard">
                  JUHUU Dashboard
                </TextLink>{" "}
                to manage your parking spots, pricing, and customers all from
                one place.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink
                  target="_blank"
                  href="https://docs.juhuu.app/articles/67039df735ed1ec9444a717c"
                >
                  Front End Partners
                </TextLink>{" "}
                to explore our growing network of partner platforms for
                increased visibility and growth.
              </ProductFeatureItem>
            </ProductFeature>
          </ProductBody>
          <ProductDisplay>
            <img src={car} className="w-full rounded-xl" />
          </ProductDisplay>
        </ProductSection>
      </Container>
      <Container className="py-24 bg-white">
        <ProductSection>
          <ProductBody>
            <ProductHeader>
              <UserGroupIcon />
              <ProductHeading>Shared Mobility</ProductHeading>
            </ProductHeader>
            <ProductSlogan>
              Free floating or station based? Why not both!
            </ProductSlogan>
            <ProductDescription>
              JUHUU offers operators of shared mobility solutions the ability to
              seamlessly integrate their fleets into our platform. Whether
              you're managing scooters, bikes, or cars, you'll gain access to
              features like free floating or station based rentals, real-time
              monitoring, tariff management, and automated invoicing. By joining
              JUHUU, your services become discoverable and bookable through our
              app and partner networks, giving you instant access to a large
              user base.
            </ProductDescription>
            <ProductCallToAction>
              <Button href={"https://dashboard.juhuu.app/"}>Start</Button>
              <Button plain href="/contact">
                Contact Sales
              </Button>
            </ProductCallToAction>
            <ProductFeature>
              <ProductFeatureItem>
                <TextLink target="_blank" href="/app">
                  JUHUU App
                </TextLink>{" "}
                to let users use your shared mobility services.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="/dashboard">
                  JUHUU Dashboard
                </TextLink>{" "}
                to manage shared mobility fleets, pricing, and customers all
                from one place in realtime.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink
                  target="_blank"
                  href="https://docs.juhuu.app/articles/67039df735ed1ec9444a717c"
                >
                  Front End Partners
                </TextLink>{" "}
                to discover the growing network of platforms connected to JUHUU.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink
                  target="_blank"
                  href="https://docs.juhuu.app/articles/67011e6e35ed1ec9444a3c1d"
                >
                  JUHUU Buddy
                </TextLink>{" "}
                to learn how AI-powered customer support can enhance user
                satisfaction
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink
                  target="_blank"
                  href="https://docs.juhuu.app/articles/67039d9e35ed1ec9444a7136"
                >
                  Mobility Service Provider
                </TextLink>{" "}
                to see how JUHUU works for Mobility Service Providers.
              </ProductFeatureItem>
            </ProductFeature>
          </ProductBody>
          <ProductDisplay>
            <img src={scooter} className="w-full rounded-xl" />
          </ProductDisplay>
        </ProductSection>
      </Container>
      <Container className="py-24">
        <ProductSection>
          <ProductBody>
            <ProductHeader>
              <GlobeEuropeAfricaIcon />
              <ProductHeading>Tourism</ProductHeading>
            </ProductHeader>
            <ProductSlogan>
              Let JUHUU boost your shared tourism business effortlessly
            </ProductSlogan>
            <ProductDescription>
              JUHUU helps shared tourism operators connect with travelers by
              digitalizing their rental fleet. Whether you offer bike rentals,
              kayak tours, or boat sharing for scenic experiences, our system
              makes it simple to manage rentals, oversee bookings in real time,
              and give your customers the convenience of an intuitive booking
              process through our app or integrated partner platforms
            </ProductDescription>
            <ProductCallToAction>
              <Button href={"https://dashboard.juhuu.app/"}>Start</Button>
              <Button plain href="/contact">
                Contact Sales
              </Button>
            </ProductCallToAction>
            <ProductFeature>
              <ProductFeatureItem>
                <TextLink target="_blank" href="/app">
                  JUHUU App
                </TextLink>{" "}
                to see how tourists use your services.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="/dashboard">
                  JUHUU Dashboard
                </TextLink>{" "}
                to manage your tourism fleet in realtime.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="/kit">
                  Digitize Kit
                </TextLink>{" "}
                to effortlessly digitalize your tourism rental equipment.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink
                  target="_blank"
                  href="https://docs.juhuu.app/articles/67039df735ed1ec9444a717c"
                >
                  Partner Front Ends
                </TextLink>{" "}
                to see how your tourism services can be integrated into other
                platforms for wider visibility.
              </ProductFeatureItem>
            </ProductFeature>
          </ProductBody>
          <ProductDisplay>
            <img src={tourism} className="w-full rounded-xl" />
          </ProductDisplay>
        </ProductSection>
      </Container>
      <div className="h-32" />
    </>
  );
};

export default UseCaseRoute;
