import React from "react";
import { Heading, Subheading } from "../../components/heading";
import { Divider } from "../../components/divider";

import { Text, TextLink } from "../../components/text";
import HeroSection from "../../sections/hero/hero-section";
import Container from "../../components/container";
import {
  ProductBody,
  ProductCallToAction,
  ProductDescription,
  ProductDisplay,
  ProductFeature,
  ProductFeatureItem,
  ProductHeader,
  ProductHeading,
  ProductSection,
  ProductSlogan,
} from "../../sections/product/product-section";
import {
  CodeBracketIcon,
  Cog6ToothIcon,
  PaintBrushIcon,
} from "@heroicons/react/16/solid";
import { Button } from "../../components/button";
import sdk_preview from "./sdk_preview.png";

interface DeveloperRouteProps {}

const DeveloperRoute: React.FC<DeveloperRouteProps> = () => {
  return (
    <>
      <HeroSection
        heading={"Developers"}
        subtitle="JUHUU is a key partner in mobility solutions. We offer a wide range of services and know-how to help you build the next generation of mobility solutions on top of our platform."
      />
      <Container className="py-24">
        <ProductSection>
          <ProductBody>
            <ProductHeader>
              <CodeBracketIcon />
              <ProductHeading>Software Development Kit</ProductHeading>
            </ProductHeader>
            <ProductSlogan>Develop with ease using our SDK</ProductSlogan>
            <ProductDescription>
              JUHUU offers an easy to use Typescript SDK to interact with our
              API.
            </ProductDescription>
            <ProductCallToAction>
              <Button
                href="https://www.npmjs.com/package/@juhuu/sdk-ts"
                target="_blank"
              >
                Download SDK
              </Button>
            </ProductCallToAction>
            <ProductFeature>
              <ProductFeatureItem>
                <TextLink
                  href="https://www.postman.com/juhuu-labs/juhuu-public/documentation/uh2gwzx/juhuu"
                  target="_blank"
                >
                  Postman Collection
                </TextLink>{" "}
                to try our endpoints.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="https://api.juhuu.app/v1">
                  API Reference
                </TextLink>{" "}
                for other programming languages locations
              </ProductFeatureItem>
            </ProductFeature>
          </ProductBody>
          <ProductDisplay>
            <img
              src={sdk_preview}
              style={{
                width: "80%",
              }}
            />
          </ProductDisplay>
        </ProductSection>
      </Container>
      <Container className="py-24">
        <ProductSection>
          <ProductBody>
            <ProductHeader>
              <Cog6ToothIcon />
              <ProductHeading>Hardware Development Assistance</ProductHeading>
            </ProductHeader>
            <ProductSlogan>
              Develop your own product and integrate it into JUHUU
            </ProductSlogan>
            <ProductDescription>
              We know a thing or two about electronics and software and are
              capable of picking you up right where you are. That's a metaphor,
              of course, taxis are avaialble in the JUHUU app, not here.
              Anyways, we just want you to know we are here to help.
            </ProductDescription>
            <ProductCallToAction>
              <Button href={"/contact"}>Contact Sales</Button>
            </ProductCallToAction>
            {/* <ProductFeature>
              <ProductFeatureItem>
                <TextLink
                  href="https://www.postman.com/juhuu-labs/juhuu-public/documentation/uh2gwzx/juhuu"
                  target="_blank"
                >
                  Postman Collection
                </TextLink>{" "}
                to try our endpoints.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink
                  href="https://www.npmjs.com/package/@juhuu/sdk-ts"
                  target="_blank"
                >
                  NPM
                </TextLink>{" "}
                to download our Typescript SDK.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink target="_blank" href="https://api.juhuu.app/v1" target="_blank">
                  API Reference
                </TextLink>{" "}
                for other programming languages locations
              </ProductFeatureItem>
            </ProductFeature> */}
          </ProductBody>
          <ProductDisplay></ProductDisplay>
        </ProductSection>
      </Container>
    </>
  );
};

export default DeveloperRoute;
