import React, { FC } from "react";
import google from "./google.webp";

interface PlayStoreImageProps {
  className?: string;
}

const PlayStoreImage: FC<PlayStoreImageProps> = ({ className }) => {
  return (
    <img
      src={google}
      className={"cursor-pointer " + className}
      onClick={() =>
        window.open(
          "https://play.google.com/store/apps/details?id=com.juhuu_bikebox.juhuu",
          "_blank"
        )
      }
    />
  );
};

export default PlayStoreImage;
