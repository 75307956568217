import React, {
  useContext,
  useState,
  createContext,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { JUHUU } from "@juhuu/sdk-ts";
import { Base64 } from "js-base64";
import { juhuu } from "../juhuuClass";
import validateAddress from "../validators/validateAddress";
import {
  Dialog,
  DialogActions,
  DialogDescription,
  DialogTitle,
} from "../components/dialog";
import { TextLink } from "../components/text";
import { Button } from "../components/button";

const CookieContext = createContext<{
  handleDecision: (decision: "accepted" | "rejected") => void;
  allowCookies: boolean;
}>({
  handleDecision: async () => null,
  allowCookies: false,
});

export function useCookie() {
  return useContext(CookieContext);
}

interface CookieProviderProps {
  children?: React.ReactNode;
}

const CookieProvider: React.FC<CookieProviderProps> = ({ children = null }) => {
  const [cookieState, setCookieState] = useState<
    "notDecided" | "accepted" | "rejected"
  >("notDecided");

  const allowCookies: boolean = useMemo(() => {
    if (cookieState === "accepted") {
      return true;
    }

    return false;
  }, [cookieState]);

  useEffect(() => {
    const cookieState = localStorage.getItem("cookieState");

    if (cookieState === "accepted") {
      setCookieState("accepted");
    } else if (cookieState === "rejected") {
      setCookieState("rejected");
    }
  }, []);

  const handleDecision = useCallback((decision: "accepted" | "rejected") => {
    localStorage.setItem("cookieState", decision);
    setCookieState(decision);
  }, []);

  return (
    <CookieContext.Provider
      value={{
        handleDecision,
        allowCookies,
      }}
    >
      {children}
      <Dialog
        open={cookieState === "notDecided"}
        onClose={() => handleDecision("rejected")}
        style={{
          zIndex: 100,
          position: "absolute",
        }}
      >
        <DialogTitle>Cookies!</DialogTitle>
        <DialogDescription>
          We use cookies to improve your experience. Read our{" "}
          <TextLink href="https://docs.juhuu.app/legal/cookies" target="_blank">
            cookie policy
          </TextLink>
          .
        </DialogDescription>
        <DialogActions>
          <Button onClick={() => handleDecision("rejected")} plain>
            Reject
          </Button>
          <Button onClick={() => handleDecision("accepted")}>Accept</Button>
        </DialogActions>
      </Dialog>
    </CookieContext.Provider>
  );
};

export default CookieProvider;
