import React from "react";
import { Heading, Subheading } from "../../components/heading";
import { Strong, Text, TextLink } from "../../components/text";
import ReactMarkdown from "react-markdown";
import { Divider } from "../../components/divider";
import { Button } from "../../components/button";
import Container from "../../components/container";

interface ProductCallToActionProps {
  children: React.ReactNode;
}

const ProductCallToAction: React.FC<ProductCallToActionProps> = ({
  children,
}) => {
  return (
    <div className="flex flex-row justify-start items-center gap-2 mb-16">
      {children}
    </div>
  );
};

export { ProductCallToAction };

interface ProductHeaderProps {
  children: React.ReactNode;
}

const ProductHeader: React.FC<ProductHeaderProps> = ({ children }) => {
  return (
    <div className="flex flex-row justify-start items-center gap-2 mb-3 data-[slot=icon]:*:size-6 data-[slot=icon]:*:fill-purple-500">
      {children}
    </div>
  );
};

export { ProductHeader };

interface ProductDisplayProps {
  children?: React.ReactNode;
}

const ProductDisplay: React.FC<ProductDisplayProps> = ({ children }) => {
  return (
    <div
      className="w-full xl:w-[calc(50%+12rem)] flex flex-row justify-end items-start xl:mr-[-12rem]"
      style={{
        position: "relative",
      }}
    >
      {children}
    </div>
  );
};

export { ProductDisplay };

interface ProductFeatureItemProps {
  children: React.ReactNode;
}

const ProductFeatureItem: React.FC<ProductFeatureItemProps> = ({
  children,
}) => {
  return <div className="text-zinc-600">{children}</div>;
};

export { ProductFeatureItem };

interface ProductFeatureProps {
  children: React.ReactNode;
}

const ProductFeature: React.FC<ProductFeatureProps> = ({ children }) => {
  return (
    <div className="flex flex-col justify-start items-start gap-1">
      <p className="font-medium">Features</p>
      {children}
    </div>
  );
};

export { ProductFeature };

interface ProductDescriptionProps {
  children: React.ReactNode;
}

const ProductDescription: React.FC<ProductDescriptionProps> = ({
  children,
}) => {
  return <Text className="mb-6">{children}</Text>;
};

export { ProductDescription };

interface ProductSloganProps {
  children?: React.ReactNode;
}

const ProductSlogan: React.FC<ProductSloganProps> = ({ children }) => {
  return <p className="text-4xl mb-8 font-bold">{children}</p>;
};

export { ProductSlogan };

interface ProductHeadingProps {
  children: React.ReactNode;
}

const ProductHeading: React.FC<ProductHeadingProps> = ({ children }) => {
  return <p className="text-lg font-bold">{children}</p>;
};

export { ProductHeading };

interface ProductBodyProps {
  children: React.ReactNode;
}

const ProductBody: React.FC<ProductBodyProps> = ({ children }) => {
  return <div className="lg:w-1/2 w-full">{children}</div>;
};

export { ProductBody };

interface ProductSectionProps {
  children: React.ReactNode;
}

const ProductSection: React.FC<ProductSectionProps> = ({ children }) => {
  return <div className="flex flex-col lg:flex-row gap-16">{children}</div>;
};

export { ProductSection };
