import React from "react";
import { Route, Routes } from "react-router-dom";
import Page from "./Page";
import HomeRoute from "./routes/home/HomeRoute";
import ScrollToTop from "./routes/ScrollToTop";
import KitRoute from "./routes/kit/KitRoute";
import ErrorRoute from "./routes/error/ErrorRoute";
import DashboardRoute from "./routes/dashboard/DashboardRoute";
import AppRoute from "./routes/app/AppRoute";
import PricingRoute from "./routes/pricing/PricingRoute";
import MarketplaceRoute from "./routes/marketplace/MarketplaceRoute";
import ContactRoute from "./routes/contact/ContactRoute";
import DeveloperRoute from "./routes/developer/DeveloperRoute";
import QrScanCallToActionRoute from "./routes/qrScanCallToAction/QrScanCallToAction";
import UseCaseRoute from "./routes/useCase/UseCaseRoute";

function App() {
  // function onClose() {
  //   console.log("close");
  // }

  // const { accountSetupCompleted } = useUser();

  return (
    <>
      {/* <Dialog open={accountSetupCompleted === false} onClose={onClose}>
        <DialogTitle>Login or signup</DialogTitle>
        <DialogDescription>
          Before continuing to the dashboard, please login or signup with your
          JUHUU-Account.
        </DialogDescription>
        <DialogActions>
          <Button
            plain
            onClick={() => window.open("https://identity.juhuu.app/support")}
          >
            Help & Support
          </Button>
          <Button
            onClick={() =>
              (window.location.href =
                "https://identity.juhuu.app/auth?refURL=https://dashboard.juhuu.app")
            }
          >
            Login or signup
          </Button>
        </DialogActions>
      </Dialog> */}
      <Page>
        <ScrollToTop />
        <Routes>
          <Route path="*" element={<HomeRoute />} />
          <Route path="/" element={<HomeRoute />} />
          <Route path="/kit" element={<KitRoute />} />
          <Route path="/dashboard" element={<DashboardRoute />} />
          <Route path="/use-cases" element={<UseCaseRoute />} />
          <Route path="/app" element={<AppRoute />} />
          <Route path="/pricing" element={<PricingRoute />} />
          <Route path="/marketplace" element={<MarketplaceRoute />} />
          <Route path="/developer" element={<DeveloperRoute />} />
          <Route path="/contact" element={<ContactRoute />} />
          <Route
            path="/qr/fiveLetterQr/:fiveLetterQr"
            element={<QrScanCallToActionRoute />}
          />
          <Route path="/download" element={<QrScanCallToActionRoute />} />
          <Route
            path="/rent/bikebox/:fiveLetterQr"
            element={<QrScanCallToActionRoute />}
          />
        </Routes>
      </Page>
    </>
  );
}

export default App;
