import React, { useMemo, forwardRef } from "react";

interface ContainerProps {
  children?: React.ReactNode;
  className?: string | undefined;
  hideDashedLines?: boolean;
}

const Container = forwardRef<HTMLDivElement, ContainerProps>(
  ({ children, className, hideDashedLines: hideDashedLinesExternal }, ref) => {
    const hideDashedLines = useMemo(() => {
      if (hideDashedLinesExternal !== undefined) {
        return hideDashedLinesExternal;
      }

      // Check if a Tailwind background was set on the className
      const backgroundSet = className?.includes("bg-");

      if (backgroundSet) {
        return false;
      }
      return true;
    }, [hideDashedLinesExternal]);

    return (
      <div ref={ref} className={`w-full relative ${className}`}>
        {/* Dashed lines */}
        {hideDashedLines === false && (
          <div className="absolute inset-0 z-10">
            {[...Array(5)].map((_, index) => (
              <div
                key={index}
                style={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  left: `${(index + 1) * 16.67}%`, // Adjust the spacing across the width
                  width: "1px",
                  borderRight: "1px dashed rgba(0, 0, 0, 0.1)",
                }}
                className="hidden lg:block"
              />
            ))}
          </div>
        )}
        {/* Main content */}
        <div className="relative mx-auto max-w-6xl px-4 z-20">{children}</div>
      </div>
    );
  }
);

Container.displayName = "Container";

export default Container;
