import React, { useCallback, useEffect, useState } from "react";
import { Heading, Subheading } from "../../components/heading";
import { Text, TextLink } from "../../components/text";
import HeroSection from "../../sections/hero/hero-section";
import Container from "../../components/container";
import ScheduleMeeting from "../../components/schedule-meeting";
import { useParams } from "react-router-dom";
import { juhuu } from "../../juhuuClass";
import { JUHUU } from "@juhuu/sdk-ts";
import CallToActionSection from "../../sections/callToAction/call-to-action-section";
import google from "./../footer/google.webp";
import apple from "./../footer/apple.webp";
import AppStoreImage from "../../components/apple/app-store-image";
import PlayStoreImage from "../../components/google/play-store-image";

interface QrScanCallToActionRouteProps {}

const QrScanCallToActionRoute: React.FC<QrScanCallToActionRouteProps> = () => {
  const { fiveLetterQr } = useParams<{ fiveLetterQr: string }>();
  const [link, setLink] = useState<JUHUU.Link.Object | null>(null);
  const [error, setError] = useState<"none" | "linkNotFound" | "requestError">(
    "none"
  );

  const handleRefresh = useCallback(async () => {
    if (fiveLetterQr === undefined) {
      return;
    }

    const query = await juhuu.links.list({
      fiveLetterQr: fiveLetterQr,
    });

    if (query.ok === false) {
      setError("requestError");
      setLink(null);
      return;
    }

    if (query.data.length !== 1) {
      setError("linkNotFound");
      setLink(null);
      return;
    }

    setError("none");
    setLink(query.data[0]);
  }, [fiveLetterQr]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  return (
    <>
      <HeroSection
        heading={link?.name || "Hello!"}
        subtitle="Download the JUHUU app now to continue!"
      ></HeroSection>
      <Container>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 md:gap-x-44 px-16 mb-32 items-center justify-items-center py-8">
          <AppStoreImage />
          <PlayStoreImage />
        </div>
      </Container>
      <Container>
        <CallToActionSection />
      </Container>
    </>
  );
};

export default QrScanCallToActionRoute;
