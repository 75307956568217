import React from "react";
import { InlineWidget } from "react-calendly";
import { Heading } from "./heading";
import { Strong, Text } from "./text";
import { useCookie } from "../context/CookieContext";
import SendInquiry from "./send-inquiry";

interface ScheduleMeetingProps {}

const ScheduleMeeting: React.FC<ScheduleMeetingProps> = ({}) => {
  const { allowCookies } = useCookie();
  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 mb-16 gap-16">
        <div className="">
          <Heading className="mb-4">Let us reach out to you!</Heading>
          <SendInquiry message="contact component" />
        </div>
        <div>
          <Heading className="mb-4">Let's connect</Heading>
          <Text>
            Text us at <Strong>office@juhuu.app</Strong>
          </Text>
          <Text>
            Call us at <Strong>+43 660 9919 009</Strong>
          </Text>
        </div>
      </div>

      {allowCookies === true && (
        <>
          <Heading>Schedule a with Ben</Heading>
          <Text>
            Do you have a specific use case in mind or want to explore a
            partnership with JUHUU? Schedule a meeting with Ben Müller-Niklas!
            Simply choose a time that works for you using the calendar below.
            Feel free to send us details about your needs, ideas, or use case
            via email beforehand, so we can better prepare for our conversation.
          </Text>
          <InlineWidget
            url="https://calendly.com/ben-muellerniklas/meeting"
            styles={{
              height: "1000px",
              width: "100%",
            }}
          />
        </>
      )}
    </>
  );
};

export default ScheduleMeeting;
