import React from "react";
import { Heading, Subheading } from "../../components/heading";
import { Divider } from "../../components/divider";

import { Text, TextLink } from "../../components/text";
import HeroSection from "../../sections/hero/hero-section";
import Container from "../../components/container";
import {
  ProductBody,
  ProductCallToAction,
  ProductDescription,
  ProductDisplay,
  ProductFeature,
  ProductFeatureItem,
  ProductHeader,
  ProductHeading,
  ProductSection,
  ProductSlogan,
} from "../../sections/product/product-section";
import {
  BanknotesIcon,
  Cog6ToothIcon,
  GlobeEuropeAfricaIcon,
  PaintBrushIcon,
  UserGroupIcon,
} from "@heroicons/react/16/solid";
import { Button } from "../../components/button";
import hero from "./hero.png";
import YouTubeVideo from "../../components/youtube-video";
import finance_billing from "./finance_billing.png";
import support_documentation from "./support_documentation.png";
import branding_appearance from "./branding_appearance.png";
import business_operations from "./business_operations.png";
import engineering from "./engineering.png";

interface DashboardRouteProps {}

const DashboardRoute: React.FC<DashboardRouteProps> = () => {
  return (
    <>
      <HeroSection
        heading={"Management Dashboard"}
        primaryAction={"Open Dashboard"}
        subtitle="Our all-in-one dashboard enables operators to manage tariffs, track rentals, monitor customer reservations, and configure devices effortlessly. Control every aspect of your business operations from a single, intuitive interface."
        primaryActionHref="https://dashboard.juhuu.app"
        secondaryAction={"Contact Sales"}
        secondaryActionHref="/contact"
      >
        <div
          style={{
            width: "160%",
            // border: "1px solid red",
            position: "relative",
            top: "0px",
            left: "0px",
          }}
        >
          <img
            src={hero}
            alt=""
            style={{
              width: "1000px",
              position: "absolute",
              left: "250px",
            }}
          />
        </div>
      </HeroSection>
      <Container className="py-24 mt-64 bg-white">
        <ProductSection>
          <ProductBody>
            <ProductHeader>
              <GlobeEuropeAfricaIcon />
              <ProductHeading>Business & Operations</ProductHeading>
            </ProductHeader>
            <ProductSlogan>
              Efficiently manage your tariffs and transactions
            </ProductSlogan>
            <ProductDescription>
              With JUHUU's Business & Operations dashboard, operators can
              smoothly adjust tariff systems and track all rentals and
              reservations in real time, providing complete control over their
              products and services.
            </ProductDescription>
            <ProductCallToAction>
              <Button href={"https://dashboard.juhuu.app/"}>Start</Button>
              <Button plain href="/contact">
                Contact Sales
              </Button>
            </ProductCallToAction>
            <ProductFeature>
              <ProductFeatureItem>
                <TextLink
                  target="_blank"
                  href="https://docs.juhuu.app/articles/67011ab535ed1ec9444a3aaf"
                >
                  Tariffs
                </TextLink>{" "}
                for pricing and optimizing your products and services.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink
                  target="_blank"
                  href="https://docs.juhuu.app/articles/66e9e97a9f98e98c8be2320a"
                >
                  Rentals and Reservations
                </TextLink>{" "}
                to track bookings.
              </ProductFeatureItem>
            </ProductFeature>
          </ProductBody>
          <ProductDisplay>
            <YouTubeVideo
              url="https://www.youtube.com/watch?v=QotaBvsu14A"
              thumbnail={business_operations}
            />
          </ProductDisplay>
        </ProductSection>
      </Container>
      <Container className="py-24 bg-violet-100">
        <ProductSection>
          <ProductBody>
            <ProductHeader>
              <BanknotesIcon />
              <ProductHeading>Finance & Billing</ProductHeading>
            </ProductHeader>
            <ProductSlogan>
              Track your payouts and invoices with ease
            </ProductSlogan>
            <ProductDescription>
              In the Finance & Billing section, operators can download invoices
              generated through JUHUU's invoicing services, create and track
              payouts, and manage accounting areas across multiple business
              units or countries.
            </ProductDescription>
            <ProductCallToAction>
              <Button href={"https://dashboard.juhuu.app/"}>Start</Button>
              <Button plain href="/contact">
                Contact Sales
              </Button>
            </ProductCallToAction>
            <ProductFeature>
              <ProductFeatureItem>
                <TextLink
                  target="_blank"
                  href="https://docs.juhuu.app/articles/67011c5435ed1ec9444a3b37"
                >
                  Invoicing Service
                </TextLink>{" "}
                to download and manage all customer invoices.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink
                  target="_blank"
                  href="https://docs.juhuu.app/articles/67011e1235ed1ec9444a3bd3"
                >
                  Payouts
                </TextLink>{" "}
                to track, and manage payouts to your bank accounts.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink
                  target="_blank"
                  href="https://docs.juhuu.app/articles/67011d2035ed1ec9444a3b7b"
                >
                  Accounting Areas
                </TextLink>{" "}
                for multi-country businesses.
              </ProductFeatureItem>
            </ProductFeature>
          </ProductBody>
          <ProductDisplay>
            <img src={finance_billing} className="w-full" />
          </ProductDisplay>
        </ProductSection>
      </Container>
      <Container className="py-24 bg-white">
        <ProductSection>
          <ProductBody>
            <ProductHeader>
              <PaintBrushIcon />
              <ProductHeading>Branding & Appearence</ProductHeading>
            </ProductHeader>
            <ProductSlogan>
              Simplify the management of your locations
            </ProductSlogan>
            <ProductDescription>
              Operators can configure new locations and products to ensure
              they're easily found by users. QR codes can be generated for
              direct product access. Locations and their settings are fully
              customizable to suit business needs.
            </ProductDescription>
            <ProductCallToAction>
              <Button href={"https://dashboard.juhuu.app/"}>Start</Button>
              <Button plain href="/contact">
                Contact Sales
              </Button>
            </ProductCallToAction>
            <ProductFeature>
              <ProductFeatureItem>
                <TextLink
                  target="_blank"
                  href="https://docs.juhuu.app/articles/6701162935ed1ec9444a3945"
                >
                  Markers
                </TextLink>{" "}
                for visibility on the JUHUU map.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink
                  target="_blank"
                  href="https://docs.juhuu.app/articles/6701183135ed1ec9444a3a51"
                >
                  QR-Codes
                </TextLink>{" "}
                to for quick product access.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink
                  target="_blank"
                  href="https://docs.juhuu.app/articles/6701175835ed1ec9444a39d7"
                >
                  Locations
                </TextLink>{" "}
                to organize product locations efficiently
              </ProductFeatureItem>
            </ProductFeature>
          </ProductBody>
          <ProductDisplay>
            <YouTubeVideo
              url="https://www.youtube.com/watch?v=vV7GC14Kw8U"
              thumbnail={branding_appearance}
            />
          </ProductDisplay>
        </ProductSection>
      </Container>
      <Container className="py-24 bg-violet-100">
        <ProductSection>
          <ProductBody>
            <ProductHeader>
              <UserGroupIcon />
              <ProductHeading>Support & Documentation</ProductHeading>
            </ProductHeader>
            <ProductSlogan>
              Enhance customer support and get relevant customer insights.
            </ProductSlogan>
            <ProductDescription>
              Operators can upload articles like help guides and FAQs, making
              them instantly available to users in the app. The JUHUU Buddy
              reads these articles to power AI-driven customer support.
              Operators also have full visibility into their customer base and
              interactions.
            </ProductDescription>
            <ProductCallToAction>
              <Button href={"https://dashboard.juhuu.app/"}>Start</Button>
              <Button plain href="/contact">
                Contact Sales
              </Button>
            </ProductCallToAction>
            <ProductFeature>
              <ProductFeatureItem>
                <TextLink
                  target="_blank"
                  href="https://docs.juhuu.app/articles/670392b635ed1ec9444a6d51"
                >
                  Documentation
                </TextLink>{" "}
                to help users with articles, guides, and FAQs.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink
                  target="_blank"
                  href="https://docs.juhuu.app/articles/66e9fd559f98e98c8be2354e"
                >
                  Customer overview
                </TextLink>{" "}
                to track who is using your products.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink
                  target="_blank"
                  href="https://docs.juhuu.app/articles/67011e6e35ed1ec9444a3c1d"
                >
                  JUHUU Buddy
                </TextLink>{" "}
                supports your customers with AI while you are not available.
              </ProductFeatureItem>
            </ProductFeature>
          </ProductBody>
          <ProductDisplay>
            <YouTubeVideo
              url="https://www.youtube.com/watch?v=r5TSV91_L4M"
              thumbnail={support_documentation}
            />
          </ProductDisplay>
        </ProductSection>
      </Container>
      <Container className="py-24 bg-white">
        <ProductSection>
          <ProductBody>
            <ProductHeader>
              <Cog6ToothIcon />
              <ProductHeading>Engineering</ProductHeading>
            </ProductHeader>
            <ProductSlogan>
              Streamline device management and customize interfaces
            </ProductSlogan>
            <ProductDescription>
              Operators can access key device parameters, create custom
              interfaces, and program devices using a drag-and-drop interface.
              This section also enables managing SIM cards and overseeing the
              connections of each device, ensuring smooth operations and full
              control over the system.
            </ProductDescription>
            <ProductCallToAction>
              <Button href={"https://dashboard.juhuu.app/"}>Start</Button>
              <Button plain href="/contact">
                Contact Sales
              </Button>
            </ProductCallToAction>
            <ProductFeature>
              <ProductFeatureItem>
                <TextLink
                  target="_blank"
                  href="https://docs.juhuu.app/articles/670393df35ed1ec9444a6e18"
                >
                  SIM-Management
                </TextLink>{" "}
                to manage SIM cards across your devices.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink
                  target="_blank"
                  href="https://docs.juhuu.app/articles/6703944b35ed1ec9444a6e46"
                >
                  Device Templates
                </TextLink>{" "}
                for custom interfaces and code editing.
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink
                  target="_blank"
                  href="https://docs.juhuu.app/articles/670394b835ed1ec9444a6e8c"
                >
                  Digital Twins{" "}
                </TextLink>
                for a realtime overview of your devices
              </ProductFeatureItem>
              <ProductFeatureItem>
                <TextLink
                  target="_blank"
                  href="https://docs.juhuu.app/articles/6703955b35ed1ec9444a6ec4"
                >
                  Connectors
                </TextLink>{" "}
                manage connectivity of your infrastructure.
              </ProductFeatureItem>
            </ProductFeature>
          </ProductBody>
          <ProductDisplay>
            <YouTubeVideo
              url="https://www.youtube.com/watch?v=fXBCHeBFBK4"
              thumbnail={engineering}
            />
          </ProductDisplay>
        </ProductSection>
      </Container>
      <div className="h-96" />
    </>
  );
};

export default DashboardRoute;
