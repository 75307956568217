import React, { useMemo, useState } from "react";
import { Button } from "../../components/button";
import Card, { EmptyCard } from "../../components/card";
import { Heading } from "../../components/heading";
import SendInquiry from "../../components/send-inquiry";
import { Text } from "../../components/text";

const optionArray: RadioProps[] = [
  {
    id: "1",
    title: "Control Kit",
    description: "Ultra high range plug and play controller.",
    subheading: "Choose your control unit",
    optionArray: [
      {
        id: "1.1",
        title: "Control Unit Single",
        description:
          "Connect up to 30 single controllers to Control Kit. Each Single Controller controls one electronic lock.",
        subheading: "Choose your electronic lock",
        optionArray: [
          {
            id: "1.1.1",
            title: "KR-S70N (recommended)",
            description:
              "Small, waterproof, robust. With built in automatic hook and door detection",
            optionArray: [],
          },
          {
            id: "1.1.2",
            title: "Custom lock",
            description:
              "Choose a custom lock to connect to Control Kit. We integrate any lock you need.",
            optionArray: [],
          },
        ],
      },
      {
        id: "1.3",
        title: "Control Unit 12",
        description:
          "Connect up to 10 Control Units to Control Kit. Each Control Unit controls 12 electronic locks.",
        subheading: "Choose your electronic lock",
        optionArray: [
          {
            id: "1.1.1",
            title: "KR-S70N (recommended)",
            description:
              "Small, waterproof, robust. With built in automatic hook and door detection",
            optionArray: [],
          },
          {
            id: "1.1.2",
            title: "Custom lock",
            description:
              "Choose a custom lock to connect to Control Kit. We integrate any lock you need.",
            optionArray: [],
          },
        ],
      },
      {
        id: "1.4",
        title: "Control Unit 48",
        description:
          "Connect up to 5 Control Units to Control Kit. Each Control Unit controls 48 electronic locks.",
        subheading: "Choose your electronic lock",
        optionArray: [
          {
            id: "1.1.1",
            title: "KR-S70N (recommended)",
            description:
              "Small, waterproof, robust. With built in automatic hook and door detection",
            optionArray: [],
          },
          {
            id: "1.1.2",
            title: "Custom lock",
            description:
              "Choose a custom lock to connect to Control Kit. We integrate any lock you need.",
            optionArray: [],
          },
        ],
      },
      {
        id: "1.5",
        title: "Custom Control Unit",
        description:
          "Connect one or more custom control units to Control Kit to control locks or any other electrical device such as bike lifts, EV-chargers, gates, doors, etc.",
        subheading: "Choose your electronic lock",
        optionArray: [
          {
            id: "1.1.1",
            title: "KR-S70N (recommended)",
            description:
              "Small, waterproof, robust. With built in automatic hook and door detection",
            optionArray: [],
          },
          {
            id: "1.1.2",
            title: "Custom lock",
            description:
              "Choose a custom lock to connect to Control Kit. We integrate any lock you need.",
            optionArray: [],
          },
        ],
      },
    ],
  },
  {
    id: "2",
    title: "Control Kit Pro",
    description:
      "Medium Range plug and play controller with 100 Mbit/s high-bandwidth interface.",
    subheading: "Choose your high-bandwidth device",
    optionArray: [
      {
        id: "1.1",
        title: "Live stream CCTV surveillance camera",
        description:
          "Connect one or more live stream CCTV surveillance cameras to Control Kit Pro. Video feeds are shown in the JUHUU Dashboard.",
        subheading: "Choose your control unit",
        optionArray: [
          {
            id: "1.1",
            title: "Control Unit Single",
            description:
              "Connect up to 30 single controllers to Control Kit. Each Single Controller controls one electronic lock.",
            subheading: "Choose your electronic lock",
            optionArray: [
              {
                id: "1.1.1",
                title: "KR-S70N (recommended)",
                description:
                  "Small, waterproof, robust. With built in automatic hook and door detection",
                optionArray: [],
              },
              {
                id: "1.1.2",
                title: "Custom lock",
                description:
                  "Choose a custom lock to connect to Control Kit. We integrate any lock you need.",
                optionArray: [],
              },
            ],
          },
          {
            id: "1.3",
            title: "Control Unit 12",
            description:
              "Connect up to 10 Control Units to Control Kit. Each Control Unit controls 12 electronic locks.",
            subheading: "Choose your electronic lock",
            optionArray: [
              {
                id: "1.1.1",
                title: "KR-S70N (recommended)",
                description:
                  "Small, waterproof, robust. With built in automatic hook and door detection",
                optionArray: [],
              },
              {
                id: "1.1.2",
                title: "Custom lock",
                description:
                  "Choose a custom lock to connect to Control Kit. We integrate any lock you need.",
                optionArray: [],
              },
            ],
          },
          {
            id: "1.4",
            title: "Control Unit 48",
            description:
              "Connect up to 5 Control Units to Control Kit. Each Control Unit controls 48 electronic locks.",
            subheading: "Choose your electronic lock",
            optionArray: [
              {
                id: "1.1.1",
                title: "KR-S70N (recommended)",
                description:
                  "Small, waterproof, robust. With built in automatic hook and door detection",
                optionArray: [],
              },
              {
                id: "1.1.2",
                title: "Custom lock",
                description:
                  "Choose a custom lock to connect to Control Kit. We integrate any lock you need.",
                optionArray: [],
              },
            ],
          },
          {
            id: "1.5",
            title: "Custom Control Unit",
            description:
              "Connect one or more custom control units to Control Kit to control locks or any other electrical device such as bike lifts, EV-chargers, gates, doors, etc.",
            subheading: "Choose your electronic lock",
            optionArray: [
              {
                id: "1.1.1",
                title: "KR-S70N (recommended)",
                description:
                  "Small, waterproof, robust. With built in automatic hook and door detection",
                optionArray: [],
              },
              {
                id: "1.1.2",
                title: "Custom lock",
                description:
                  "Choose a custom lock to connect to Control Kit. We integrate any lock you need.",
                optionArray: [],
              },
            ],
          },
        ],
      },
      {
        id: "1.2",
        title: "Custom high-bandwidth device",
        description:
          "Connect one or more custom high-bandwidth devices to Control Kit Pro. We integrate any interface you need.",
        subheading: "Choose your control unit",
        optionArray: [
          {
            id: "1.1",
            title: "Control Unit Single",
            description:
              "Connect up to 30 single controllers to Control Kit. Each Single Controller controls one electronic lock.",
            subheading: "Choose your electronic lock",
            optionArray: [
              {
                id: "1.1.1",
                title: "KR-S70N (recommended)",
                description:
                  "Small, waterproof, robust. With built in automatic hook and door detection",
                optionArray: [],
              },
              {
                id: "1.1.2",
                title: "Custom lock",
                description:
                  "Choose a custom lock to connect to Control Kit. We integrate any lock you need.",
                optionArray: [],
              },
            ],
          },
          {
            id: "1.3",
            title: "Control Unit 12",
            description:
              "Connect up to 10 Control Units to Control Kit. Each Control Unit controls 12 electronic locks.",
            subheading: "Choose your electronic lock",
            optionArray: [
              {
                id: "1.1.1",
                title: "KR-S70N (recommended)",
                description:
                  "Small, waterproof, robust. With built in automatic hook and door detection",
                optionArray: [],
              },
              {
                id: "1.1.2",
                title: "Custom lock",
                description:
                  "Choose a custom lock to connect to Control Kit. We integrate any lock you need.",
                optionArray: [],
              },
            ],
          },
          {
            id: "1.4",
            title: "Control Unit 48",
            description:
              "Connect up to 5 Control Units to Control Kit. Each Control Unit controls 48 electronic locks.",
            subheading: "Choose your electronic lock",
            optionArray: [
              {
                id: "1.1.1",
                title: "KR-S70N (recommended)",
                description:
                  "Small, waterproof, robust. With built in automatic hook and door detection",
                optionArray: [],
              },
              {
                id: "1.1.2",
                title: "Custom lock",
                description:
                  "Choose a custom lock to connect to Control Kit. We integrate any lock you need.",
                optionArray: [],
              },
            ],
          },
          {
            id: "1.5",
            title: "Custom Control Unit",
            description:
              "Connect one or more custom control units to Control Kit to control locks or any other electrical device such as bike lifts, EV-chargers, gates, doors, etc.",
            subheading: "Choose your electronic lock",
            optionArray: [
              {
                id: "1.1.1",
                title: "KR-S70N (recommended)",
                description:
                  "Small, waterproof, robust. With built in automatic hook and door detection",
                optionArray: [],
              },
              {
                id: "1.1.2",
                title: "Custom lock",
                description:
                  "Choose a custom lock to connect to Control Kit. We integrate any lock you need.",
                optionArray: [],
              },
            ],
          },
        ],
      },
    ],
  },
];

type RadioProps = {
  id: string;
  title: string;
  description: string;
  imgSrc?: string;
  optionArray: RadioProps[];
  subheading?: string;
};

interface KitConfiguratorSectionProps {}

const RadioOption: React.FC<{
  options: RadioProps[];
  selectedOptionId: string | null;
  onSelect: (selected: RadioProps) => void;
}> = ({ options, selectedOptionId, onSelect }) => {
  return (
    <div className={`grid gap-4 w-full mb-4  grid-cols-1`}>
      {options.map((option) => (
        <Card
          key={option.id}
          title={option.title}
          description={option.description}
          onClick={() => onSelect(option)}
          className={
            selectedOptionId === option.id
              ? "bg-zinc-100"
              : selectedOptionId === null
              ? ""
              : "hidden"
          }
        />
      ))}
    </div>
  );
};

const KitConfiguratorSection: React.FC<KitConfiguratorSectionProps> = () => {
  const [selectedOptions, setSelectedOptions] = useState<RadioProps[]>([]);

  const handleSelect = (option: RadioProps, level: number) => {
    setSelectedOptions((prev) => {
      const isSelected = prev[level]?.id === option.id;
      if (isSelected) {
        // Deselect the option by slicing out all options from this level onward
        return prev.slice(0, level);
      } else {
        // Select the option and replace at the current level
        return [...prev.slice(0, level), option];
      }
    });
  };

  // Filter out only the selected options data
  const selectedOptionsData = useMemo(
    () =>
      selectedOptions.map(({ title, description }) => ({
        title,
        description,
      })),
    [selectedOptions]
  );

  return (
    <div className="flex flex-col items-start">
      <Heading>Build your ideal electronics kit</Heading>
      <Text className="mb-8">
        Pick and choose from our powerful components to design a setup that
        perfectly meets your unique needs.
      </Text>
      <div className="flex flex-col justify-start w-full">
        <RadioOption
          options={optionArray}
          selectedOptionId={selectedOptions[0]?.id || null}
          onSelect={(option) => handleSelect(option, 0)}
        />

        {selectedOptions.map((selectedOption, index) => (
          <>
            <Heading className="my-4" key={`${selectedOption.id}-heading`}>
              {selectedOptions[index]?.subheading ?? null}
            </Heading>
            <div key={selectedOption.id}>
              {selectedOption.optionArray.length > 0 && (
                <RadioOption
                  options={selectedOption.optionArray}
                  selectedOptionId={selectedOptions[index + 1]?.id || null}
                  onSelect={(option) => handleSelect(option, index + 1)}
                />
              )}
            </div>
          </>
        ))}
        {selectedOptions[selectedOptions.length - 1]?.optionArray.length ===
          0 && (
          <>
            <Heading className="flex-none mb-8">
              We got all we need. Type in your email and we will reach out to
              you.
            </Heading>

            <SendInquiry
              message={JSON.stringify(selectedOptionsData, null, 2)}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default KitConfiguratorSection;
